import React from "react";
import {
  TextInput,
  List,
  LinkSettings,
  TextArea,
} from "@raketa-cms/raketa-cms";
import SpacingInput from "../_shared/SpacingInput";
import HighlightsInput from "./HighlightsInput";
import AdditionsInput from "./AdditionsInput";
import FooterInput from "./FooterInput";

const TAB_TEMPLATE = {
  title: "Tab",
  icon: "https://placeholder.raketa.cloud/images/16x16",
  active_icon: "https://placeholder.raketa.cloud/images/16x16",
  highlights: HighlightsInput.defaults,
  additions: AdditionsInput.defaults,
  footer: FooterInput.defaults,
};

const TabInput = ({ settings, onChangeItem }) => (
  <>
    <TextInput
      label="Title"
      onChange={(value) => onChangeItem("title", value)}
      value={settings.title}
    />

    <TextInput
      label="Icon"
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <TextInput
      label="Active Icon"
      onChange={(value) => onChangeItem("active_icon", value)}
      value={settings.active_icon}
    />

    <div style={{ display: "flex", gap: "1rem" }}>
      <HighlightsInput
        value={settings.highlights}
        onChange={(value) => onChangeItem("highlights", value)}
      />

      <AdditionsInput
        value={settings.additions}
        onChange={(value) => onChangeItem("additions", value)}
      />

      <FooterInput
        value={settings.footer}
        onChange={(value) => onChangeItem("footer", value)}
      />
    </div>
  </>
);

const TabsInput = ({ value, onChange }) => {
  return (
    <List
      label="Tabs"
      listItem={(settings, onChangeItem) => (
        <TabInput settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="title"
      template={TAB_TEMPLATE}
    />
  );
};

const LinkInput = ({ settings, onChangeItem }) => (
  <>
    <TextInput
      label="Icon"
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <LinkSettings
      label="Link"
      value={settings.link}
      onChange={(value) => onChangeItem("link", value)}
    />

    <TextArea
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <TextInput
      label="Tag"
      onChange={(value) => onChangeItem("tag", value)}
      value={settings.tag}
    />

    <TextInput
      label="Tag Color"
      onChange={(value) => onChangeItem("tag_color", value)}
      value={settings.tag_color}
    />
  </>
);

const LinksInput = ({ value, onChange }) => {
  return (
    <List
      label="Mobile links (visible after the tabs)"
      listItem={(settings, onChangeItem) => (
        <LinkInput settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="link.label"
      template={{
        link: LinkSettings.defaults,
        tag: "",
        icon: "",
        tag_color: "",
        description: "",
      }}
    />
  );
};

const Tabs = {};

Tabs.primaryField = "";

Tabs.defaults = {
  spacing: "",
  tabs: [
    {
      id: 1,
      ...TAB_TEMPLATE,
    },
  ],
  mobile_links: [],
};

Tabs.admin = ({ settings, onChange }) => {
  return (
    <>
      <SpacingInput
        value={settings.spacing}
        onChange={(value) => onChange("spacing", value)}
      />

      <TabsInput
        onChange={(value) => onChange("tabs", value)}
        value={settings.tabs}
      />

      <LinksInput
        onChange={(value) => onChange("mobile_links", value)}
        value={settings.mobile_links}
      />
    </>
  );
};

export default Tabs;
