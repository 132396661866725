import React from "react";
import PagePicker from "../Fields/PagePicker/PagePicker";
import { List, RaketaUIProvider } from "@raketa-cms/raketa-cms";

function ListItem({ pages, pagesUrl, settings, onChangeItem }) {
  const page = pages[settings?.page?.slug] || {};

  return (
    <PagePicker
      label="Page"
      page={{ ...page }}
      search_url={pagesUrl}
      onSelect={(newValue) =>
        onChangeItem("page", {
          slug: newValue.slug,
        })
      }
    />
  );
}

function Pageslist({ pages, pagesUrl, label, value, onChange }) {
  return (
    <List
      label={label}
      listItem={(settings, onChangeItem) => (
        <ListItem
          pages={pages}
          pagesUrl={pagesUrl}
          settings={settings}
          onChangeItem={onChangeItem}
        />
      )}
      onChangeList={(_, selectOptions) =>
        onChange(selectOptions.map((so) => so.page.slug))
      }
      items={value.map((slug, idx) => ({ id: idx, page: { slug } }))}
      primaryField="page.slug"
      template={{ page: {} }}
    />
  );
}

function PageFilters({ value, name, search_url, pages }) {
  const [state, setState] = React.useState(value);
  console.log({ state });
  return (
    <RaketaUIProvider>
      <Pageslist
        pages={pages}
        pagesUrl={search_url}
        label="Pages to include"
        value={value.included || []}
        onChange={(newVal) => setState({ ...state, included: newVal })}
      />
      <Pageslist
        pages={pages}
        pagesUrl={search_url}
        label="Pages to exclude"
        value={value.excluded || []}
        onChange={(newVal) => setState({ ...state, excluded: newVal })}
      />

      <input type="hidden" name={name} defaultValue={JSON.stringify(state)} />
    </RaketaUIProvider>
  );
}

export default PageFilters;
