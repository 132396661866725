import React from "react";
import {
  TextInput,
  SelectMenu,
  LinkSettings,
  List,
  TextArea,
} from "@raketa-cms/raketa-cms";
import SpacingInput from "./_shared/SpacingInput";

const LinkInput = ({ settings, onChangeItem }) => (
  <>
    <LinkSettings
      label="Link"
      value={settings.link}
      onChange={(value) => onChangeItem("link", value)}
    />

    <SelectMenu
      label="Arrow variant"
      options={[
        ["none", "None"],
        ["chevron", "Chevron"],
        ["arrow", "Arrow"],
      ]}
      value={settings.arrow}
      onChange={(value) => onChangeItem("arrow", value)}
    />
  </>
);

const LinksInput = ({ value, onChange }) => {
  return (
    <List
      listItem={(settings, onChangeItem) => (
        <LinkInput settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="link.label"
      template={{
        link: LinkSettings.defaults,
        arrow: "chevron",
      }}
    />
  );
};

const Banner = {};

Banner.primaryField = "";

Banner.defaults = {
  spacing: "",
  links: [],
};

Banner.admin = ({ settings, onChange }) => {
  return (
    <>
      <SpacingInput
        value={settings.spacing}
        onChange={(value) => onChange("spacing", value)}
      />

      <LinksInput
        onChange={(value) => onChange("links", value)}
        value={settings.links}
      />

      <TextInput
        label="Icon"
        onChange={(value) => onChange("icon", value)}
        value={settings.icon}
      />

      <TextArea
        label="Description"
        onChange={(value) => {
          onChange("description", value);
        }}
        value={settings.description}
      />

      <TextInput
        label="Logo"
        onChange={(value) => onChange("logo", value)}
        value={settings.logo}
      />
    </>
  );
};

export default Banner;
