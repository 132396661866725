import React from "react";
import { SelectMenu } from "@raketa-cms/raketa-cms";

function SpacingInput({ value, onChange }) {
  return (
    <SelectMenu
      label="Spacing"
      options={[
        ["top-sm", "Top (small)"],
        ["top-md", "Top (medium)"],
        ["top-lg", "Top (large)"],
        ["bottom-sm", "Bottom (small)"],
        ["bottom-md", "Bottom (medium)"],
        ["bottom-lg", "Bottom (large)"],
        ["both-sm", "Both (small)"],
        ["both-md", "Both (medium)"],
        ["both-lg", "Both (large)"],
      ]}
      value={value}
      onChange={(newValue) => onChange(newValue)}
    />
  );
}

export default SpacingInput;
