import Title from "./Title";
import Image from "./Image";
import HTML from "./HTML";
import Links from "./Links";
import Banner from "./Banner";
import Tabs from "./Tabs";

export default {
  Title,
  Image,
  HTML,
  Links,
  Banner,
  Tabs,
};
