import React from "react";
import AdminModal from "components/admin/Modal/Modal";
import AdminModalHeader from "components/admin/Modal/ModalHeader";
import AdminModalBody from "components/admin/Modal/ModalBody";
import AdminModalFooter from "components/admin/Modal/ModalFooter";

const FloatingInput = ({ label, children }) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="floating-input">
      <button
        type="button"
        className="btn btn-default"
        onClick={(_event) => {
          setOpen(!open);
        }}
      >
        {label}
      </button>

      <AdminModal open={open}>
        <AdminModalHeader title={label} />
        <AdminModalBody>{children}</AdminModalBody>

        <AdminModalFooter>
          <button
            type="button"
            className="btn btn-success"
            onClick={() => setOpen(false)}
          >
            Close
          </button>
        </AdminModalFooter>
      </AdminModal>
    </div>
  );
};

export default FloatingInput;
