import React from "react";
import { TextInput, SelectMenu } from "@raketa-cms/raketa-cms";
import SpacingInput from "./_shared/SpacingInput";

const Title = {};

Title.primaryField = "title";

Title.defaults = {
  title: "Title",
  icon: "",
  underline: "yes",
};

Title.admin = ({ settings, onChange }) => {
  return (
    <>
      <SpacingInput
        onChange={(value) => onChange("spacing", value)}
        value={settings.spacing}
      />

      <TextInput
        label="Title"
        onChange={(value) => onChange("title", value)}
        value={settings.title}
      />

      <TextInput
        label="Icon"
        onChange={(value) => onChange("icon", value)}
        value={settings.icon}
      />

      <SelectMenu
        label="Underline"
        options={[
          ["yes", "Yes"],
          ["no", "No"],
        ]}
        value={settings.underline}
        onChange={(value) => onChange("underline", value)}
      />
    </>
  );
};

export default Title;
