import React from "react";
import {
  LinkSettings,
  List,
  SelectMenu,
  TextInput,
} from "@raketa-cms/raketa-cms";
import FloatingInput from "./FloatingInput";

const ITEM_TEMPLATE = { link: LinkSettings.defaults };
const SECTION_TEMPLATE = {
  title: "",
  items: [{ ...ITEM_TEMPLATE, id: 1 }],
};

const ItemsInput = ({ value, onChange }) => {
  return (
    <List
      label="Items"
      listItem={(settings, onChangeItem) => (
        <LinkSettings
          label="Link"
          value={settings.link}
          onChange={(value) => onChangeItem("link", value)}
        />
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="link.label"
      template={ITEM_TEMPLATE}
    />
  );
};

const SectionsInput = ({ value, onChange }) => {
  return (
    <List
      label="Sections"
      listItem={(settings, onChangeItem) => (
        <>
          <TextInput
            label="Title"
            value={settings.title}
            onChange={(value) => onChangeItem("title", value)}
          />

          <ItemsInput
            value={settings.items}
            onChange={(value) => onChangeItem("items", value)}
          />
        </>
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="title"
      template={SECTION_TEMPLATE}
    />
  );
};

const FooterInput = ({ value, onChange }) => {
  return (
    <FloatingInput label="Edit footer">
      <SelectMenu
        label="Gradient variant"
        options={[
          ["1", "Yellow"],
          ["2", "Orange"],
          ["3", "Blue"],
        ]}
        value={value.gradient}
        onChange={(newValue) => onChange({ ...value, gradient: newValue })}
      />

      <SectionsInput
        onChange={(newValue) => onChange({ ...value, sections: newValue })}
        value={value.sections}
      />
    </FloatingInput>
  );
};

FooterInput.defaults = {
  gradient: "1",
  sections: [{ id: 1, ...SECTION_TEMPLATE }],
};
export default FooterInput;
