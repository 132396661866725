import React from "react";
import {
  TextInput,
  SelectMenu,
  LinkSettings,
  List,
  TextArea,
} from "@raketa-cms/raketa-cms";
import SpacingInput from "./_shared/SpacingInput";

const LinkInput = ({ settings, onChangeItem }) => (
  <>
    <TextInput
      label="Icon"
      onChange={(value) => onChangeItem("icon", value)}
      value={settings.icon}
    />

    <LinkSettings
      label="Link"
      value={settings.link}
      onChange={(value) => onChangeItem("link", value)}
    />

    <TextArea
      label="Description"
      onChange={(value) => onChangeItem("description", value)}
      value={settings.description}
    />

    <TextInput
      label="Tag"
      onChange={(value) => onChangeItem("tag", value)}
      value={settings.tag}
    />

    <TextInput
      label="Tag Color"
      onChange={(value) => onChangeItem("tag_color", value)}
      value={settings.tag_color}
    />

    <SelectMenu
      label="Arrow variant"
      options={[
        ["none", "None"],
        ["chevron", "Chevron"],
        ["arrow", "Arrow"],
      ]}
      value={settings.arrow}
      onChange={(value) => onChangeItem("arrow", value)}
    />
  </>
);

const LinksInput = ({ value, onChange }) => {
  return (
    <List
      label="Links"
      listItem={(settings, onChangeItem) => (
        <LinkInput settings={settings} onChangeItem={onChangeItem} />
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="link.label"
      template={{
        link: LinkSettings.defaults,
        tag: "",
        icon: "",
        tag_color: "",
        arrow: "chevron",
        description: "",
      }}
    />
  );
};

const Links = {};

Links.primaryField = "";

Links.defaults = {
  spacing: "",
  links: [],
};

Links.admin = ({ settings, onChange }) => {
  return (
    <>
      <SpacingInput
        value={settings.spacing}
        onChange={(value) => onChange("spacing", value)}
      />

      <LinksInput
        onChange={(value) => onChange("links", value)}
        value={settings.links}
      />
    </>
  );
};

export default Links;
