import React from "react";
import {
  TextInput,
  LinkSettings,
  List,
  TextArea,
} from "@raketa-cms/raketa-cms";
import FloatingInput from "./FloatingInput";

const CARD_TEMPLATE = {
  link: LinkSettings.defaults,
  icon: "https://placeholder.raketa.cloud/images/20x20",
  description: "Lorem ipsum",
};

const CardsInput = ({ value, onChange }) => {
  return (
    <List
      label="Cards"
      listItem={(settings, onChangeItem) => (
        <>
          <LinkSettings
            label="Link"
            value={settings.link}
            onChange={(value) => onChangeItem("link", value)}
            hint="The link label is used as the card title."
          />

          <TextInput
            label="Icon"
            onChange={(value) => onChangeItem("icon", value)}
            value={settings.icon}
          />

          <TextArea
            label="Description"
            onChange={(value) => onChangeItem("description", value)}
            value={settings.description}
          />
        </>
      )}
      onChangeList={(_, newList) => onChange(newList)}
      items={value}
      primaryField="link.label"
      template={CARD_TEMPLATE}
    />
  );
};

const AdditionsInput = ({ value, onChange }) => {
  return (
    <FloatingInput label="Edit additions">
      <TextInput
        label="Title"
        onChange={(newValue) => onChange({ ...value, title: newValue })}
        value={value.title}
      />

      <CardsInput
        onChange={(newValue) => onChange({ ...value, cards: newValue })}
        value={value.cards}
      />
    </FloatingInput>
  );
};

AdditionsInput.defaults = {
  title: "Explore!",
  cards: [
    {
      id: 1,
      ...CARD_TEMPLATE,
    },
  ],
};

export default AdditionsInput;
