import React from "react";
import {
  TextInput,
  SelectMenu,
  TextArea,
  LinkSettings,
} from "@raketa-cms/raketa-cms";
import SpacingInput from "./_shared/SpacingInput";

const Image = {};

Image.defaults = {
  image: "https://placeholder.raketa.cloud/images/1920x1080",
  link: LinkSettings.defaults,
  description: "",
  variant: "16/9",
};

Image.admin = ({ settings, onChange }) => {
  return (
    <>
      <SpacingInput
        value={settings.spacing}
        onChange={(value) => onChange("spacing", value)}
      />

      <TextInput
        label="Image"
        onChange={(value) => onChange("image", value)}
        value={settings.image}
      />

      <LinkSettings
        label="Link"
        value={settings.link}
        onChange={(value) => onChange("link", value)}
      />

      <TextArea
        label="Description"
        onChange={(value) => {
          onChange("description", value);
        }}
        value={settings.description}
      />

      <SelectMenu
        label="Aspect ration"
        options={[
          ["16/9", "16:9"],
          ["1/1", "1:1"],
          ["32/10", "32:10"],
        ]}
        value={settings.aspect_ratio}
        onChange={(value) => onChange("aspect_ratio", value)}
      />
    </>
  );
};

export default Image;
